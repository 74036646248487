import { routerConfig } from '@entando/router';

import store from 'state/store';

export const ROUTE_WMS_DASHBOARD = 'dashboard';
export const ROUTE_WMS_ORDER_FULFILLMENT = 'order-fulfillment';
export const ROUTE_WMS_PENDING_ORDERS = 'pending-orders';
export const ROUTE_WMS_SHIPPED_ORDERS = 'shipped-orders';
export const ROUTE_WMS_RETURNED_ORDERS = 'returned-orders';
export const ROUTE_WMS_RETURN_AUTHORIZATION = 'return-authorization';
export const ROUTE_WMS_THIRD_PARTY = 'third-party';
export const ROUTE_WMS_PURCHASE_ORDERS = 'purchase-orders';
export const ROUTE_WMS_SUPPLY_RECEIPTS = 'supply-receipts';
export const ROUTE_WMS_SUPPORT = 'support';
export const ROUTE_WMS_SUPPORT_CHAT = 'support-chat';
export const ROUTE_WMS_HOLIDAYS = 'calendar';
export const ROUTE_WMS_SETTINGS = 'manage-clients';
export const ROUTE_WMS_ACCESS = 'manage-access';
export const ROUTE_LOGIN = 'login';
export const ROUTE_RESETPASS = 'reset';
export const ROUTE_RESETLINK = 'reset-link';
export const ROUTE_404 = 'notFound';

export const PATH_WMS_DASHBOARD = '/';
export const PATH_WMS_ORDER_FULFILLMENT = '/order-fulfillment';
export const PATH_WMS_PENDING_ORDERS = '/pending-orders';
export const PATH_WMS_SHIPPED_ORDERS = '/shipped-orders';
export const PATH_WMS_RETURNED_ORDERS = '/returned-orders';
export const PATH_WMS_RETURN_AUTHORIZATION = '/return-authorization';
export const PATH_WMS_THIRD_PARTY = '/third-party';
export const PATH_WMS_PURCHASE_ORDERS = '/purchase-orders';
export const PATH_WMS_SUPPLY_RECEIPTS = '/supply-receipts';
export const PATH_WMS_SUPPORT = '/support';
export const PATH_WMS_SUPPORT_CHAT = '/support/chat/:id';
export const PATH_WMS_HOLIDAYS = '/calendar';
export const PATH_WMS_SETTINGS = '/manage-clients';
export const PATH_WMS_ACCESS = '/manage-access';
export const PATH_LOGIN = '/login';
export const PATH_RESETPASS = '/reset';
export const PATH_RESETLINK = '/reset-link';
export const PATH_404 = '/notFound';

export const routes = [
  { name: ROUTE_LOGIN, path: PATH_LOGIN },
  { name: ROUTE_RESETPASS, path: PATH_RESETPASS },
  { name: ROUTE_WMS_DASHBOARD, path: PATH_WMS_DASHBOARD },
  { name: ROUTE_WMS_ORDER_FULFILLMENT, path: PATH_WMS_ORDER_FULFILLMENT },
  { name: ROUTE_WMS_PENDING_ORDERS, path: PATH_WMS_PENDING_ORDERS },
  { name: ROUTE_WMS_SHIPPED_ORDERS, path: PATH_WMS_SHIPPED_ORDERS },
  { name: ROUTE_WMS_RETURNED_ORDERS, path: PATH_WMS_RETURNED_ORDERS },
  { name: ROUTE_WMS_RETURN_AUTHORIZATION, path: PATH_WMS_RETURN_AUTHORIZATION },
  { name: ROUTE_WMS_THIRD_PARTY, path: PATH_WMS_THIRD_PARTY },
  { name: ROUTE_WMS_PURCHASE_ORDERS, path: PATH_WMS_PURCHASE_ORDERS },
  { name: ROUTE_WMS_SUPPLY_RECEIPTS, path: PATH_WMS_SUPPLY_RECEIPTS },
  { name: ROUTE_WMS_SUPPORT, path: PATH_WMS_SUPPORT },
  { name: ROUTE_WMS_SUPPORT_CHAT, path: PATH_WMS_SUPPORT_CHAT },
  { name: ROUTE_WMS_HOLIDAYS, path: PATH_WMS_HOLIDAYS },
  { name: ROUTE_WMS_SETTINGS, path: PATH_WMS_SETTINGS },
  { name: ROUTE_WMS_ACCESS, path: PATH_WMS_ACCESS },
  { name: ROUTE_RESETLINK, path: PATH_RESETLINK },
];

export const matchRoute = (theRoutes, url) => {
  for (let i = 0; i < theRoutes.length; i += 1) {
    const route = theRoutes[i];
    const match = route.re.exec(url);
    if (match) {
      const params = {};
      for (let j = 0; j < route.keys.length; j += 1) {
        params[route.keys[j].name] = match[j + 1];
      }
      return { route, params };
    }
  }
  return null;
};


routerConfig(
  store,
  {
    mode: 'browser',
    routes,
    notFoundRoute: { name: ROUTE_404, path: '/404' },
  },
);
