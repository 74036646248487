import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import PageLoader from 'ui/components/loader/PageLoader';
import { ReactComponent as DashboardIcon } from 'resources/icons/dashboard.svg';
import { ReactComponent as ReceiptIcon } from 'resources/icons/receipt.svg';
import { ReactComponent as OrderFulfillment } from 'resources/icons/order-fulfillment.svg';
import { ReactComponent as PurchaseOrder } from 'resources/icons/purchase-order.svg';
/* import { ReactComponent as SupportCall } from 'resources/icons/support-call.svg'; */
import {
  PATH_WMS_DASHBOARD,
  PATH_WMS_ORDER_FULFILLMENT,
  PATH_WMS_PURCHASE_ORDERS,
  PATH_WMS_SUPPLY_RECEIPTS,
  /* PATH_WMS_SUPPORT, */
} from 'app-init/routedir';
import style from './RouteStyle';

const PageLogin = Loadable({
  loader: () => import(/* webpackChunkName: "login" */'ui/components/auth/LoginContainer'),
  loading: PageLoader,
});

const PageResetPass = Loadable({
  loader: () => import(/* webpackChunkName: "login" */'ui/components/auth/ResetPass'),
  loading: PageLoader,
});

const PageResetLink = Loadable({
  loader: () => import(/* webpackChunkName: "reset_link" */'ui/components/auth/ResetLink'),
  loading: PageLoader,
});

// begin wms
const WMSDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */'ui/pages/wms/dashboard/WMSDashboard'),
  loading: PageLoader,
});

const WMSOrders = Loadable({
  loader: () => import(/* webpackChunkName: "orders" */'ui/pages/wms/orders/OrdersContainer'),
  loading: PageLoader,
});

const WMSPendingOrders = Loadable({
  loader: () => import(/* webpackChunkName: "pending_orders" */'ui/pages/wms/orders/PendingOrdersContainer'),
  loading: PageLoader,
});

const WMSShippedOrders = Loadable({
  loader: () => import(/* webpackChunkName: "shipped_orders" */'ui/pages/wms/orders/ShippedOrdersContainer'),
  loading: PageLoader,
});

const WMSReturnedOrders = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'ui/pages/wms/orders/ReturnedOrdersContainer'),
  loading: PageLoader,
});

const WMSPageReturnAuthorization = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'ui/pages/wms/orders/ReturnAuthorizationContainer'),
  loading: PageLoader,
});

const WMSPageThirdParty = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'ui/pages/wms/orders/ThirdPartyContainer'),
  loading: PageLoader,
});

const WMSPurchaseOrders = Loadable({
  loader: () => import(/* webpackChunkName: "products_vendor_orders" */'ui/pages/wms/purchase/VendorOrdersContainer'),
  loading: PageLoader,
});

const WMSSupplyReceipts = Loadable({
  loader: () => import(/* webpackChunkName: "products_vendor_orders" */'ui/pages/wms/purchase/supplyreceipts/SupplyReceiptsContainer'),
  loading: PageLoader,
});

/* const WMSSupport = Loadable({
  loader: () => import(webpackChunkName: "support"'ui/pages/wms/support/SupportContainer'),
  loading: PageLoader,
}); */

const WMSCalendar = Loadable({
  loader: () => import(/* webpackChunkName: "calendar" */'ui/pages/wms/holidays/HolidaysContainer'),
  loading: PageLoader,
});

const WMSSettings = Loadable({
  loader: () => import(/* webpackChunkName: "settings" */'ui/pages/wms/settings/SettingsContainer'),
  loading: PageLoader,
});

const WMSAccess = Loadable({
  loader: () => import(/* webpackChunkName: "access" */'ui/pages/wms/settings/AccessContainer'),
  loading: PageLoader,
});

export const wmsMenus = [
  { label: 'Dashboard', url: PATH_WMS_DASHBOARD, icon: <DashboardIcon /> },
  { label: 'Order Fulfillment', url: PATH_WMS_ORDER_FULFILLMENT, icon: <OrderFulfillment /> },
  { label: 'Purchase Orders', url: PATH_WMS_PURCHASE_ORDERS, icon: <PurchaseOrder /> },
  { label: 'Supply Receipts', url: PATH_WMS_SUPPLY_RECEIPTS, icon: <ReceiptIcon /> },
  /* { label: 'Support', url: PATH_WMS_SUPPORT, icon: <SupportCall /> }, */
];
// end wms

class Routes extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChanged(this.props.location, prevProps.location);
    }
  }
  render() {
    const { classes, location } = this.props;
    const container = (location.pathname !== '/login' && location.pathname !== '/reset') ? classes.container : '';
    return (
      <div className={container}>
        <Switch>
          <Route exact path="/" component={WMSDashboard} />
          <Route path="/login" component={PageLogin} />
          <Route path="/reset" component={PageResetPass} />
          <Route path="/reset-link" component={PageResetLink} />
          <Route path="/order-fulfillment" component={WMSOrders} />
          <Route path="/pending-orders" component={WMSPendingOrders} />
          <Route path="/shipped-orders" component={WMSShippedOrders} />
          <Route path="/returned-orders" component={WMSReturnedOrders} />
          <Route path="/return-authorization" component={WMSPageReturnAuthorization} />
          <Route path="/third-party" component={WMSPageThirdParty} />
          <Route path="/purchase-orders" component={WMSPurchaseOrders} />
          <Route path="/supply-receipts" component={WMSSupplyReceipts} />
          {/* <Route path="/support" component={WMSSupport} />
          <Route path="/support/chat/:id" component={WMSSupport} /> */}
          <Route path="/calendar" component={WMSCalendar} />
          <Route path="/manage-clients" component={WMSSettings} />
          <Route path="/manage-access" component={WMSAccess} />
        </Switch>
      </div>
    );
  }
}

Routes.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  onRouteChanged: PropTypes.func.isRequired,
};

export default withRouter(withStyles(style)(Routes));
