export const SET_ORDERS_DATA = 'orders/set-orders-data';
export const SET_MORE_ORDERS_DATA = 'orders/set-more-orders-data';
export const SET_VENDOR_ORDERS_DATA = 'orders/set-vendor-orders-data';
export const SET_MORE_VENDOR_ORDERS_DATA = 'orders/set-more-vendor-orders-data';
export const SET_ORDER_DETAILS_DATA = 'orders/set-order-details-data';
export const RESET_ORDERS_DATA = 'orders/reset-orders-data';
export const SET_RA_DATA = 'orders/set-ra-data';
export const SET_EDIT_DATA = 'orders/set-edit-data';
export const SET_RECIPIENT_DATA = 'orders/set-recipient-data';
export const SET_CARRIER_SERVICE_DATA = 'orders/set-carrier-service-data';
export const SET_THIRD_PARTY_FORM_DATA = 'orders/set-third-party-form-data';
export const SET_THIRD_PARTY_TYPE = 'orders/set-third-party-type';
export const SET_ORDER_INFO_DATA = 'orders/set-order-info-data';
export const SET_RETURNED_ORDERS_DATA = 'orders/set-returned-orders-data';
export const SET_MORE_RETURNED_ORDERS_DATA = 'orders/set-more-returned-orders-data';
export const SET_FILTERS_DATA = 'orders/set-filters-data';
export const SET_RECIPIENT_AUTOCOMPLETE_DATA = 'orders/set-recipient-autocomplete-data';
export const RESET_RECIPIENT_AUTOCOMPLETE_DATA = 'orders/reset-recipient-autocomplete-data';
export const SET_IMPORT_INFO_DATA = 'orders/set-import-info-data';
export const SET_IMPORT_ORDERS_DATA = 'orders/set-import-orders-data';
export const SET_SORT_COLUMN = 'orders/set-sort-column';
export const SET_SORT_TYPE = 'orders/set-sort-type';
export const SET_CLIENT_AUTOCOMPLETE_DATA = 'orders/set-client-autocomplete-type';
export const RESET_CLIENT_AUTOCOMPLETE_DATA = 'orders/reset-client-autocomplete-type';
export const SET_ORDERS_AUTOCOMPLETE_DATA = 'orders/set-orders-autocomplete-data';
export const RESET_ORDERS_AUTOCOMPLETE_DATA = 'orders/reset-orders-autocomplete-data';
